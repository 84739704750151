// Deprecated featureflag enablePartnerFinancingInOtherCurrency
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useNotify,
  useResourceContext,
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';
import PartnerFinancingFormOld from './PartnerFinancingFormOld';
import { useConstantContext } from '../ConstantsContext';
import { usePartnerFinancing } from './PartnerFinancingContext';

const PartnerFinancingButtonOld = ({
  isLoadingDecision,
  setIsLoadingSendOrder,
}) => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();
  const resource = useResourceContext();
  const {
    records,
    orderCurrencies,
  } = usePartnerFinancing();
  const haveSameOrderCurrency = orderCurrencies.length === 1 || new Set(orderCurrencies).size === 1;
  const showNotification = useCallback(({ data }) => {
    notify(
      `${data.message}${data.errors.length > 0 ? `\nErrors:\n${data.errors.join('\n')}` : ''}`,
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
    setIsLoadingSendOrder(false);
  }, [notify, setIsLoadingSendOrder]);
  const {
    mutate: sendOrder,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: 'order/partner-financing',
    shouldRefresh: true,
    shouldUnselectAll: resource,
    errorMessage: t('Could not send partner financing request'),
    onSuccess: showNotification,
    onError: () => setIsLoadingSendOrder(false),
  });
  const handleClick = useCallback((formData) => {
    const selectedOrderIds = records.map((record) => record.resourceId);
    if (haveSameOrderCurrency) {
      setIsLoadingSendOrder(true);
      sendOrder({
        selectedIds: selectedOrderIds,
        financingCurrency: formData.financingCurrency,
        fxRate: formData.fxRate || 1,
      });
    }
    return true;
  }, [records, sendOrder, setIsLoadingSendOrder, haveSameOrderCurrency]);
  const shouldDisplayButton = clientInfo.defaultPartnerFinancer;
  if (!shouldDisplayButton) return null;
  return (
    <Box>
      <ModalButton
        modalTitle={t('Send to Moank')}
        actionLabel={t('Confirm')}
        openButtonLabel={t('Send to Moank')}
        onClick={handleClick}
        disabled={isLoading || isLoadingDecision}
        width="47.5rem"
        formDefaultValue={{}}
      >
        {haveSameOrderCurrency
          ? (
            <>
              <PartnerFinancingFormOld />
              <Typography paragraph>{t('This will send the financing application to Moank. If the merchant and customer has not been onboarded with Moank, this will be created first and afterwards the financing application will be sent.')}</Typography>
            </>
          )
          : <Typography paragraph>{t('The selected orders are in different currencies. All orders need to be in the same currency when using the bulk action to send to Moank for financing')}</Typography>}
      </ModalButton>
    </Box>
  );
};
PartnerFinancingButtonOld.propTypes = {
  isLoadingDecision: PropTypes.bool.isRequired,
  setIsLoadingSendOrder: PropTypes.func.isRequired,
};
export default PartnerFinancingButtonOld;
