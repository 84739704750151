import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput,
} from 'react-admin';

import { isMaxCharacterLengthStringValidation, vatNumberValidation } from '../../utils/validationErrors';

import OldFieldsGroup from '../utils/OldFieldsGroup';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];

const CoreInputGroup = ({ isMerchant }) => {
  const { choices, constants } = useConstantContext();
  const { t } = useTranslation();

  const { coreTypes: coreTypesChoices } = choices;
  const { setValue } = useFormContext();

  const coreTypeInput = useWatch({ name: 'type' });
  useEffect(() => {
    if (coreTypeInput === constants.coreTypes.CONSUMER) setValue('vatNumber', undefined);
    if (isMerchant) setValue('type', constants.coreTypes.BUSINESS);
  }, [setValue, coreTypeInput, constants, isMerchant]);

  return (
    <OldFieldsGroup
      name={isMerchant ? t('Company Identifiers') : t('Customer Identifiers')}
      withBorder
      column={4}
      fillAvailable
    >
      {!isMerchant && (
        <SelectInput
          margin="none"
          label={t('Business/Consumer')}
          source="type"
          choices={coreTypesChoices}
          validate={validateRequired}
          helperText={coreTypeInput === constants.coreTypes.BUSINESS ? t('This customer should be a business identified using the organisation number') : t('This customer should be a private individual identified using a social security number')}
        />
      )}
      {coreTypeInput === constants.coreTypes.BUSINESS && <TextInput source="vatNumber" label={t('VAT Number')} validate={[vatNumberValidation]} />}
      {coreTypeInput && <TextInput source="nationalIdentifier" label={coreTypeInput === constants.coreTypes.BUSINESS ? t('Org. Registration Number') : t('Social Security Number')} validate={[validateRequired, (value) => isMaxCharacterLengthStringValidation(value, 50)]} />}
      {coreTypeInput && (
        <ReferenceInput
          source="country"
          reference="countries"
          resource="/countries"
        >
          <AutocompleteInput
            optionText={(record) => (record && record.name ? `(${record.id}) ${record.name}` : '')}
            label={t('Country')}
            validate={validateRequired}
          />
        </ReferenceInput>
      )}
    </OldFieldsGroup>
  );
};

CoreInputGroup.propTypes = {
  isMerchant: PropTypes.bool,
};

CoreInputGroup.defaultProps = {
  isMerchant: false,
};

export default CoreInputGroup;
