import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  useNotify,
  useRecordContext,
} from 'react-admin';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';

const MarkAsInvoicedButton = () => {
  const { id: accountId } = useRecordContext();
  const { t } = useTranslation();
  const notify = useNotify();

  const path = `account/${accountId}/mark-as-invoiced`;

  const showNotification = useCallback(() => {
    notify(
      t('The billing was successfully moved to the invoiced list and marked as invoiced externally.'),
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify, t]);

  const {
    mutate: markAsInvoiced,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    onSuccess: showNotification,
    errorMessage: t('Could not mark as invoiced'),
  });

  const handleClick = useCallback(() => {
    markAsInvoiced();
    return true;
  }, [markAsInvoiced]);

  return (
    <ModalButton
      modalTitle={t('Mark as externally invoiced')}
      openButtonLabel={t('Mark as externally invoiced')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Mark as externally sent')}
      variant="subAction"
      width="30rem"
    >
      <Typography paragraph>{t('The invoice for the billing will be marked as externally sent. This will update the status in the system and move the billing to be \'Invoiced\'. Do you want to continue?')}</Typography>
    </ModalButton>
  );
};

export default MarkAsInvoicedButton;
