import React from 'react';
import { useTranslation } from 'react-i18next';

import renderReference from '../../utils/renderReference';

import { useConstantContext } from '../ConstantsContext';

import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import AutocompleteArrayInput from '../designSystem/react-admin/inputs/AutocompleteArrayInput';
import DateInput from '../designSystem/react-admin/inputs/DateInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import ReferenceArrayInput from '../designSystem/react-admin/inputs/ReferenceArrayInput';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const useOrderFilters = (filter) => {
  const { constants, clientInfo, choices } = useConstantContext();
  const { decisionStatuses: decisionStatusesConstants } = constants;
  const { decisionStatuses } = choices;
  const { entityRoles, userActions } = constants;
  const { t } = useTranslation();

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );
  const canViewLabels = useHasUserPermissions(
    constants,
    userActions.VIEW_LABELS,
  );
  let decisionChoices = decisionStatuses.filter((
    status,
  ) => status.id !== decisionStatusesConstants.PARTNER_PENDING
    && status.id !== decisionStatusesConstants.PARTNER_GRANTED);

  if (clientInfo && clientInfo.defaultPartnerFinancer) {
    decisionChoices = decisionStatuses;
  }

  const shouldFilterMerchants = filter ? !filter.sellerId : true;
  const shouldFilterCustomers = filter ? !filter.debtorId : true;

  return [
    ...(canViewMerchant && shouldFilterMerchants ? [
      <ReferenceInput
        source="sellerId"
        reference="entity-v2/reference"
        filter={{ entityRoles: entityRoles.MERCHANT }}
        alwaysOn
      >
        <AutocompleteInput
          label={t('Merchant')}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>,
    ] : []),
    ...(shouldFilterCustomers ? [
      <ReferenceInput
        source="debtorId"
        reference="entity-v2/reference"
        filter={{ entityRoles: entityRoles.CUSTOMER }}
        alwaysOn
      >
        <AutocompleteInput
          label={t('Customer')}
          optionText={renderReference}
          suggestionLimit={20}
        />
      </ReferenceInput>,
    ] : []),
    <DateInput
      label={t('Financing date (from)')}
      source="dateRange_day_from_financing_financedAt"
      alwaysOn
    />,
    <DateInput
      label={t('Financing date (to)')}
      source="dateRange_day_to_financing_financedAt"
      alwaysOn
    />,
    <SelectInput
      label={t('Payment status')}
      source="custom_invoiceStatus"
      choices={[
        { id: 'isPaid', name: t('Paid') },
        { id: 'isNotPaid', name: t('Unpaid') },
        { id: 'isLate', name: t('Overdue') },
        { id: 'isWaitingForPayment', name: t('Not yet due') },
      ]}
      alwaysOn
    />,
    ...(canViewLabels ? [
      <ReferenceArrayInput
        source="labelIds"
        reference="label"
      >
        <AutocompleteArrayInput
          label={t('Labels')}
          variant="filled"
          optionText="name"
        />
      </ReferenceArrayInput>,
    ] : []),
    <DateInput
      label={t('Creation date (from)')}
      source="dateRange_day_from_createdAt"
    />,
    <DateInput
      label={t('Creation date (to)')}
      source="dateRange_day_to_createdAt"
    />,
    <DateInput
      label={t('Due date (from)')}
      source="dateRange_day_from_invoices_expiryDate"
    />,
    <DateInput
      label={t('Due date (to)')}
      source="dateRange_day_to_invoices_expiryDate"
    />,
    <SelectInput
      label={t('Eligible for reminder')}
      source="custom_eligibleForReminder"
      choices={[
        { id: 'true', name: t('Yes') },
        { id: 'false', name: t('No') },
      ]}
    />,
    <SelectInput
      label={t('Application status')}
      source="custom_currentDecision"
      choices={decisionChoices}
    />,
    <SelectInput
      label={t('Is service')}
      source="custom_isService"
      choices={[
        { id: 'true', name: t('Yes') },
        { id: 'false', name: t('No') },
      ]}
    />,
    <SelectInput
      label={t('Financed')}
      source="custom_isFinanced"
      choices={[
        { id: 'true', name: t('Yes') },
        { id: 'false', name: t('No') },
      ]}
    />,
    <SelectInput
      label={t('Invoice status')}
      source="custom_isInvoiced"
      choices={[
        { id: 'true', name: t('Is invoiced') },
        { id: 'false', name: t('Is not invoiced') },
      ]}
    />,
    <SelectInput
      margin="none"
      label={t('Recoursed')}
      source="custom_hasRecoursed"
      choices={[
        { id: 'true', name: t('Yes') },
        { id: 'false', name: t('No') },
      ]}
    />,
    ...(clientInfo.defaultPartnerFinancer ? [
      <SelectInput
        label={t('Financer')}
        source="custom_isSentToPartner"
        choices={[
          { id: 'true', name: t('Moank AB') },
          { id: 'false', name: t('Lidion Bank PLC') },
        ]}
      />,
    ] : []),
  ];
};

export default useOrderFilters;
