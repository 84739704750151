import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import ListChip, { colors } from '../designSystem/ListChip';

const GroupedCreditStatusChip = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { accountStatus } = record;

  let chipColor;
  let chipLabel;
  if (accountStatus === 'closed') {
    chipLabel = t('Placed');
    chipColor = colors.gray;
  } else {
    chipLabel = t('Unplaced');
    chipColor = colors.red;
  }

  return (
    <ListChip
      label={chipLabel}
      chipColor={chipColor}
    />
  );
};

export default GroupedCreditStatusChip;
