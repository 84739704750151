import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityCommentsGroup from '../../EntityCommentsGroup';
import OnboardingStage from '../../OnboardingStage';
import CreditDataGroup from '../../CreditDataGroup';
import OverviewGroup from '../../OverviewGroup';
import EntityExposureGroup from '../../EntityExposureGroup';
import { CreditDataContextProvider } from '../../../CreditDataContext';

import useUserPermissionsAndRoles from '../../../hooks/useUserPermissionsAndRoles';

const SummaryTab = (props) => {
  const { t } = useTranslation();
  const { isMerchant } = useUserPermissionsAndRoles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Summary')} {...props}>
      <OnboardingStage />
      <OverviewGroup route="customer" />
      <EntityCommentsGroup />
      {!isMerchant && <EntityExposureGroup route="customer" />}
      <CreditDataContextProvider>
        <CreditDataGroup entityId="debtorId" />
      </CreditDataContextProvider>
    </FormTab>
  );
};

export default SummaryTab;
