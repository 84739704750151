import React from 'react';
import SaveOnlyToolbar from '../../utils/SaveOnlyToolbar';

import SummaryTab from './tabs/SummaryTab';
import MerchantTab from './tabs/MerchantTab';
import CustomerTab from './tabs/CustomerTab';

import TabbedForm from '../../designSystem/react-admin/TabbedForm';
import Edit from '../../designSystem/react-admin/Edit';

import FinancingApplicationTitle from './FinancingApplicationTitle';
import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import RedirectToShow from '../../utils/RedirectToShow';

const FinancingApplicationEdit = () => {
  const { isMerchant } = useUserPermissionsAndRoles();
  if (isMerchant) return <RedirectToShow />;

  return (
    <Edit
      mutationMode="optimistic"
      title={<FinancingApplicationTitle />}
      redirect={false}
      appLocation="home.financing-application.edit"
    >
      <TabbedForm
        sanitizeEmptyValues
        toolbar={<SaveOnlyToolbar />}
      >
        <SummaryTab />
        <CustomerTab />
        <MerchantTab />
      </TabbedForm>
    </Edit>
  );
};

export default FinancingApplicationEdit;
