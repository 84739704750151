import React from 'react';
import {
  ArrayField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import TransactionField from '../utils/TransactionField';

import Datagrid from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';
import TitleH4 from '../designSystem/TitleH4';

import OrderLink from '../entityv2/OrderLink';

const GroupedCreditTransactions = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleH4 title={t('Transactions')} margin="1.2rem 0 -0.3rem" />
      <SimpleShowLabeled fullWidth>
        <ArrayField source="transactions" label="">
          <Datagrid
            bulkActionButtons={false}
            variant="blueBackground"
            rightAlignedColumns={['amount', 'subActions']}
          >
            <TransactionField label={t('Type')} source="origin.type" choicesSource="transactionOrigins" />
            <WrapperField label={t('Order number/reference')} source="action">
              <OrderLink />
            </WrapperField>
            <DateField source="date" label={t('Date')} emptyText="-" />
            <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeled>
    </>
  );
};

export default GroupedCreditTransactions;
