import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import EntityListActions from '../EntityListActions';

import CurrencyField from '../../utils/CurrencyField';
import SafePagination from '../../utils/SafePagination';
import UtilisationField from '../../utils/UtilisationField';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';

import DateField from '../../designSystem/react-admin/DateField';
import List from '../../designSystem/react-admin/list/List';
import TextInputWithLens from '../../designSystem/react-admin/inputs/TextInputWithLens';
import ReferenceField from '../../designSystem/react-admin/ReferenceField';
import { DatagridConfigurable } from '../../designSystem/react-admin/Datagrid';
import TitleH1 from '../../designSystem/TitleH1';

export const getCustomerListTitle = (t) => t('Customers');

const CreditInfoReferenceField = ({
  source,
  route,
  FieldComponent,
  FieldComponentProps,
}) => (
  <ReferenceField
    referenceSource="id"
    source={source}
    reference={`entity-v2/${route}`}
    resource={`entity-v2/${route}`}
    FieldComponent={FieldComponent}
    FieldComponentProps={FieldComponentProps}
  />
);
CreditInfoReferenceField.propTypes = {
  source: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  FieldComponent: PropTypes.elementType.isRequired,
  FieldComponentProps: PropTypes.shape(),
};
CreditInfoReferenceField.defaultProps = {
  FieldComponentProps: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  sortable: false,
};

const CoreReferenceField = ({ source, FieldComponent, FieldComponentProps }) => (
  <ReferenceField
    referenceSource="entityCoreId"
    source={source}
    reference="entity-v2/core"
    resource="entity-v2/core"
    FieldComponent={FieldComponent}
    FieldComponentProps={FieldComponentProps}
  />
);
CoreReferenceField.propTypes = {
  source: PropTypes.string.isRequired,
  FieldComponent: PropTypes.elementType.isRequired,
  FieldComponentProps: PropTypes.shape(),
};
CoreReferenceField.defaultProps = {
  FieldComponentProps: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  sortable: false,
};

const CustomerList = () => {
  const { hasFactoring, isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const { constants, clientInfo } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewCreditInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isFinancer;

  const canViewAssignedCreditInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isMerchant;

  return (
    <List
      actions={<EntityListActions />}
      empty={false}
      pagination={<SafePagination />}
      mainFilterInputs={[<TextInputWithLens placeholder={t('Search name, alias, VAT/Org/SSN')} source="custom_q" />]}
      sort={{ field: 'mainAddress.name', order: 'ASC' }}
      title={<TitleH1 title={getCustomerListTitle(t)} />}
      disableSyncWithLocation
      configurable
      appLocation="home.entity-v2/customer"
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        omit={['marketplaceReferenceId', 'nationalIdentifier', 'vatNumber', 'totalGroupedCreditBalance.amount']}
        rowClick="edit"
        rightAlignedColumns={[
          'creditLimit',
          'creditLimitUtilised',
          'creditLimitUtilisedPercentage',
          'assignedCreditLimit',
          'assignedCreditLimitUtilised',
          'assignedCreditLimitUtilisedPercentage',
          'kpis\\.overdueAmount',
          'kpis\\.outstandingFinancedAmount',
          'totalGroupedCreditBalance\\.amount',
        ]}
      >
        {[
          <TextField source="marketplaceReferenceId" label={t('Alias')} />,
          <TextField source="mainAddress.name" label={t('Name')} />,
          <CoreReferenceField
            label={t('Org. number / SSN')}
            source="nationalIdentifier"
            FieldComponent={TextField}
          />,
          <DateField source="createdAt" label={t('Creation date')} />,
          <CoreReferenceField
            label={t('VAT number')}
            source="vatNumber"
            FieldComponent={TextField}
          />,
          canViewCreditInfo && (
            <CreditInfoReferenceField
              route="credit-info"
              label={t('Credit limit')}
              source="creditLimit"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'creditLimitCurrency' }}
            />
          ),
          hasFactoring && (
            <CurrencyField
              label={t('Grouped credit balance')}
              source="totalGroupedCreditBalance.amount"
              currencySource="totalGroupedCreditBalance.currency"
              emptyText="0.00"
              sortable={false}
            />
          ),
          canViewCreditInfo && (
            <CreditInfoReferenceField
              route="credit-info"
              label={t('Exposure')}
              source="creditLimitUtilised"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'creditLimitCurrency' }}
            />
          ),
          canViewAssignedCreditInfo && hasFactoring && (
            <CreditInfoReferenceField
              route="assigned-credit-info"
              label={t('Credit limit')}
              source="assignedCreditLimit"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'assignedCreditLimitCurrency' }}
            />
          ),
          canViewAssignedCreditInfo && hasFactoring && (
            <CreditInfoReferenceField
              route="assigned-credit-info"
              label={t('Exposure')}
              source="assignedCreditLimitUtilised"
              FieldComponent={CurrencyField}
              FieldComponentProps={{ currencySource: 'assignedCreditLimitCurrency' }}
            />
          ),
          isFinancer && <CurrencyField source="kpis.outstandingFinancedAmount" label={`${t('Exposure')} ${clientInfo.referenceCurrency}`} currencySource="kpis.currency" />,
          isFinancer && <CurrencyField source="kpis.overdueAmount" label={`${t('Overdue amount')} ${clientInfo.referenceCurrency}`} currencySource="kpis.currency" />,
          canViewCreditInfo && (
            <CreditInfoReferenceField
              route="credit-info"
              label={`${t('Exposure')} %`}
              source="creditLimitUtilisedPercentage"
              FieldComponent={UtilisationField}
            />
          ),
          canViewAssignedCreditInfo && hasFactoring && (
            <CreditInfoReferenceField
              route="assigned-credit-info"
              label={`${t('Exposure')} (%)`}
              source="assignedCreditLimitUtilisedPercentage"
              FieldComponent={UtilisationField}
            />
          ),
        ].filter((col) => !!col).map((col) => React.cloneElement(col, { key: col.props.source }))}
      </DatagridConfigurable>
    </List>
  );
};

export default CustomerList;
