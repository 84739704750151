import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { useRecordContext } from 'react-admin';
import { useStatistics } from '../dashboards/StatisticsContext';

import TitleH1 from '../designSystem/TitleH1';

import FieldsGroup from '../utils/FieldsGroup';

import EntityExposureCharts from './EntityExposureCharts';
import RefreshExposureKPIButton from './RefreshExposureKPIButton';
import PartnerSelectionContextProvider from './PartnerSelectionContext';
import PartnerSelection from './PartnerSelection';
import { useConstantContext } from '../ConstantsContext';

const EntityExposureGroup = ({ route }) => {
  const { t } = useTranslation();
  const record = useRecordContext();
  const { statisticsData } = useStatistics();
  const { clientInfo } = useConstantContext();
  const showPartnerSelection = clientInfo.defaultPartnerFinancer;

  const isDashboard = route === 'merchantDashboard' || route === 'financerDashboard';

  const entityCoreId = isDashboard
    ? statisticsData.entityCoreId
    : record.entityCoreId;

  return (
    <PartnerSelectionContextProvider>
      <FieldsGroup
        title={isDashboard ? (
          <Box display="flex" flexDirect="row" gap="0.625rem">
            <TitleH1 title={t('Exposure')} />
            {showPartnerSelection && <PartnerSelection />}
          </Box>
        ) : t('Exposure')}
        actions={(
          <RefreshExposureKPIButton entityCoreId={entityCoreId} />
        )}
      >
        <EntityExposureCharts route={route} />
      </FieldsGroup>
    </PartnerSelectionContextProvider>
  );
};

EntityExposureGroup.propTypes = {
  route: PropTypes.string.isRequired,
};

export default EntityExposureGroup;
