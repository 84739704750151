import React from 'react';
import {
  ShowButton,
  useRecordContext,
} from 'react-admin';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const GroupedCreditLink = () => {
  const { constants } = useConstantContext();
  const { origin } = useRecordContext();
  const { userActions } = constants;

  const canViewGroupedCredit = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  );

  if (!origin.groupedCreditAccountId || !origin.groupedCreditReference || !canViewGroupedCredit) {
    return null;
  }

  return (
    <ShowButton
      resource="grouped-credit"
      variant="blueBackgroundOnHover"
      record={{ id: origin.groupedCreditAccountId }}
      label={origin.groupedCreditReference}
      sx={{ '& .MuiButton-startIcon': { display: 'none' } }}
    />
  );
};

export default GroupedCreditLink;
