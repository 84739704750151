import React from 'react';

import {
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import isRecourseProduct from '../../utils/isRecourseProduct';
import CurrencyField from '../utils/CurrencyField';
import FeeGrid from '../utils/FeeGrid';
import FieldsGroup from '../utils/FieldsGroup';
import PercentageField from '../utils/PercentageField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import UpdateFinancingSettingsButton from './UpdateFinancingSettingsButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import TitleH3 from '../designSystem/TitleH3';
import BooleanField from '../designSystem/react-admin/BooleanField';

const FinancingLimitInfo = () => {
  const { merchantId } = useRecordContext();
  const { t } = useTranslation();
  if (!merchantId) return null;

  return (
    <>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="financingLimit" currencySource="financingLimitCurrency" label={t('Financing limit')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="financingLimitAvailable" currencySource="financingLimitCurrency" label={t('Available financing')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <CurrencyField source="financingLimitUtilised" currencySource="financingLimitCurrency" label={t('Utilised financing')} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6}>
        <SimpleShowLabeled>
          <PercentageField source="financingLimitUtilisedPercentage" label={`${t('Utilised financing limit')} (%)`} emptyText="-" />
        </SimpleShowLabeled>
      </Grid>
    </>
  );
};

export const FinancialProductsField = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { financialProductsDisplayValues } = constants;

  if (!record.financialProducts) return null;
  return (
    <List dense disablePadding>
      {record.financialProducts.map((financialProduct) => (
        <ListItem key={financialProduct} sx={{ padding: '0' }}>
          <ListItemText primary={financialProductsDisplayValues[financialProduct]} />
        </ListItem>
      ))}
    </List>
  );
};

const RecourseDaysField = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { financialProducts } = constants;

  if (!record.financialProducts) return null;

  const shouldDisplayRecourseDays = isRecourseProduct(
    record.financialProducts,
    financialProducts,
  );

  if (!shouldDisplayRecourseDays) return null;
  return (
    <Grid item lg={4} md={6}>
      <SimpleShowLabeled>
        <NumberField source="recourseDays" emptyText="-" />
      </SimpleShowLabeled>
    </Grid>
  );
};

const FinancingInfoGroup = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants, clientInfo } = useConstantContext();
  const { userActions } = constants;

  const canViewFinancingInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_FINANCIAL_SETTINGS,
  );

  if (!hasFactoring || !canViewFinancingInfo) return null;

  return (
    <ReferenceField
      label={false}
      source="entityCoreId"
      reference="entity-v2/financing-info"
      link={false}
    >
      <FieldsGroup
        title={t('Financing settings')}
        actions={<UpdateFinancingSettingsButton />}
      >
        <Grid container>
          <FinancingLimitInfo />
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <PercentageField source="financingAmountPercentage" label={`${t('Financing amount')} (%)`} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="financingLimitCurrency" label={t('Financing currency')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <RecourseDaysField label={t('Recourse days')} />
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FinancialProductsField label={t('Financial products')} />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField
                customRecord={record}
                source="paymentThreshold"
                currency={clientInfo.referenceCurrency}
                label={t('Payment threshold')}
                emptyText="-"
              />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column" margin="4.5rem 0rem">
          <TitleH3 title={t('Permission settings')} />
          <BooleanField label={t('Allow merchant payment')} source="settings.allowMerchantPayment" />
          <BooleanField label={t('Credit notes')} source="settings.enableCreditNotes" />
          <BooleanField label={t('Allow settlement account overdraft')} source="settings.allowSettlementOverdraft" />
          <BooleanField label={t('Allow requesting financing amount')} source="settings.canRequestFinancingAmount" />
          <BooleanField label={t('Reverse charge')} source="reverseCharge" />
        </Box>
        <FeeGrid source="feeSettingsV2" label={t('Fee settings')} emptyText="-" />
      </FieldsGroup>
    </ReferenceField>
  );
};

export default FinancingInfoGroup;
