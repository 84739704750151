import React, { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import { useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useExchangeRate } from '../ExchangeRateContext';

import { useConstantContext } from '../ConstantsContext';

import InputsGroup from '../designSystem/InputsGroup';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';

import validateFinancingCurrency from '../utils/validateFinancingCurrency';

import { usePartnerFinancing } from './PartnerFinancingContext';

const notSupportedMap = (currencies) => ({ // To remove when we support for more currencies
  [currencies.SWISS_FRANC]: currencies.SWEDISH_KRONA,
  [currencies.ZLOTY]: currencies.SWEDISH_KRONA,
});

const PartnerFinancingForm = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const theme = useTheme();

  const { constants, choices } = useConstantContext();
  const {
    currencies: currenciesConstants,
  } = constants;
  const { currencies } = choices;
  const exchangeRateMap = useExchangeRate();
  const { orderCurrencies } = usePartnerFinancing();

  const orderCurrency = orderCurrencies[0];
  const financingCurrency = useWatch({ name: 'financingCurrency' });
  const formFxRate = useWatch({ name: 'fxRate' });
  const defaultFinancingCurrency = notSupportedMap(currenciesConstants)[orderCurrency]
    || orderCurrency;

  useEffect(() => {
    if (exchangeRateMap[orderCurrency]) {
      const currency = financingCurrency || defaultFinancingCurrency;
      const rate = exchangeRateMap[orderCurrency][currency] || 1;
      if (!financingCurrency) {
        setValue('financingCurrency', currency);
      }
      if (!formFxRate) {
        setValue('fxRate', rate.toFixed(6));
      }
    }
  }, [
    exchangeRateMap,
    orderCurrency,
    financingCurrency,
    defaultFinancingCurrency,
    setValue,
    formFxRate,
  ]);

  const financeOtherCurrency = orderCurrency !== (financingCurrency || defaultFinancingCurrency);

  const onChangeFinancingCurrency = useCallback((e) => {
    const rate = exchangeRateMap[orderCurrency][e.target.value] || 1;
    setValue('financingCurrency', e.target.value);
    setValue('fxRate', rate.toFixed(6));
  }, [setValue, exchangeRateMap, orderCurrency]);

  const validate = validateFinancingCurrency({
    financingCurrency,
    orderCurrencies,
    fxRate: formFxRate,
  });

  return (
    <InputsGroup layout="column">
      <SelectInput
        label={t('Financing currency')}
        source="financingCurrency"
        choices={currencies}
        onChange={onChangeFinancingCurrency}
        defaultValue={defaultFinancingCurrency}
        validate={validate}
      />
      {financeOtherCurrency && (
        <NumberInput
          label={t('Financing FX rate')}
          source="fxRate"
          fimentoVariant="show"
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  color: theme.palette.switch,
                  fontSize: '15px',
                  fontWeight: '600',
                }}
                position="end"
              >
                {orderCurrency} {t('to')} {financingCurrency || defaultFinancingCurrency}
              </InputAdornment>
            ),
          }}
          disabled
        />
      )}
    </InputsGroup>
  );
};

export default PartnerFinancingForm;
