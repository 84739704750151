import React from 'react';
import {
  TextField,
  ArrayField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../../designSystem/react-admin/Datagrid';
import DescriptionField from '../../designSystem/react-admin/DescriptionField';

import FieldsGroup from '../../utils/FieldsGroup';

import { useConstantContext } from '../../ConstantsContext';

const FinancingApplicationRulesResultsGroup = () => {
  const { constants: { ruleLabelMap } } = useConstantContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Automatic decision results')}>
      <ArrayField source="decisionData.results" fullWidth selectedIds={[]}>
        <Datagrid bulkActionButtons={false} cellMaxWidth="unset">
          <FunctionField sx={{ whiteSpace: 'nowrap' }} source="ruleName" label={t('Rule name')} render={({ ruleName }) => ruleLabelMap[ruleName]} />
          <TextField source="decision" />
          <DescriptionField source="reason" emptyText="-" />
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

export default FinancingApplicationRulesResultsGroup;
