// Deprecated featureflag enablePartnerFinancingInOtherCurrency
import React, { useCallback } from 'react';

import { required } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { useConstantContext } from '../ConstantsContext';

import InputsGroup from '../designSystem/InputsGroup';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';

import { strictlyPositiveNumberValidation } from '../../utils/validationErrors';
import validateFinancingCurrency from '../utils/validateFinancingCurrency';

import { usePartnerFinancing } from './PartnerFinancingContext';

const validateRequired = [required()];

const PartnerFinancingFormOld = () => {
  const { t } = useTranslation();
  const { constants, choices } = useConstantContext();
  const { orderCurrencies } = usePartnerFinancing();
  const {
    currencies: currenciesConstants,
  } = constants;
  const { currencies } = choices;
  const { setValue } = useFormContext();
  const financingCurrency = useWatch({ name: 'financingCurrency' });
  const fxRate = useWatch({ name: 'fxRate' });

  const financeOtherCurrency = orderCurrencies
    .some((currency) => currency !== financingCurrency);

  const onChangeFinancingCurrency = useCallback((e) => {
    setValue('financingCurrency', e.target.value);
    setValue('fxRate', 1);
  }, [setValue]);

  const validate = validateFinancingCurrency({
    financingCurrency,
    orderCurrencies,
    fxRate,
  });

  return (
    <InputsGroup>
      <Box display="flex" gap="1.5rem">
        <SelectInput
          label={t('Financing currency')}
          source="financingCurrency"
          choices={currencies}
          onChange={onChangeFinancingCurrency}
          defaultValue={currenciesConstants.SWEDISH_KRONA}
          validate={validate}
        />
        {financingCurrency && financeOtherCurrency
          && (
            <NumberInput
              label={t('Financing FX rate')}
              source="fxRate"
              InputProps={{ endAdornment: <InputAdornment position="end">{orderCurrencies[0]} {t('to')} {financingCurrency}</InputAdornment> }}
              validate={[strictlyPositiveNumberValidation, ...validateRequired]}
              defaultValue={1}
            />
          )}
      </Box>
    </InputsGroup>
  );
};

export default PartnerFinancingFormOld;
