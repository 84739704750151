import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useListContext,
  useRecordContext,
  required,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import useDownloadDocument from '../hooks/useDownloadDocument';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

import ModalButton from '../designSystem/ModalButton';
import InputsGroup from '../designSystem/InputsGroup';
import CurrencyInput from '../designSystem/react-admin/inputs/CurrencyInput';

import { strictlyPositiveNumberValidation } from '../../utils/validationErrors';
import { isStrictlyPositiveNumber } from '../../utils/validators';

const validatePartialPayoutAmount = (record) => (value) => {
  const positiveValidation = strictlyPositiveNumberValidation(value);
  if (positiveValidation) return positiveValidation;

  const balance = Math.abs(record.balance);
  if (value > balance) {
    return 'Partial payout amount cannot exceed the total balance';
  }
  return undefined;
};

const PartialPayoutButton = (props) => {
  const {
    size,
    variant,
    sx,
    disabled,
    color,
  } = props;

  const { selectedIds } = useListContext();
  const { pathname } = useLocation();
  const record = useRecordContext();
  const { t } = useTranslation();
  const { id: accountId } = record || {};
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const {
    mutate: downloadFinancerOutboundPaymentReport,
    isLoading,
  } = useDownloadDocument({
    path: 'account/outbound-payment',
    errorMessage: t('Could not retrieve outbound payment report'),
    shouldRefresh: true,
    shouldUnselectAll: 'settlement-account',
  });
  const validateRequired = [required()];

  const canEditSettlement = useHasUserPermissions(
    constants,
    userActions.MANAGE_SETTLEMENT_ACCOUNTS,
  );

  const handleClick = useCallback((formValues) => {
    downloadFinancerOutboundPaymentReport({
      paramId: accountId || selectedIds,
      payload: {
        path: pathname,
        amount: formValues.partialPayoutAmount,
      },
    });
    return true;
  }, [accountId, pathname, downloadFinancerOutboundPaymentReport, selectedIds]);

  if (!canEditSettlement) return null;
  if (record && record.closedAt) return null;
  if (record && isStrictlyPositiveNumber(record.balance)) return null;

  return (
    <ModalButton
      modalTitle={t('Make partial payout')}
      openButtonLabel={t('Partial payout')}
      actionLabel={t('Partial payout')}
      disabled={disabled || isLoading}
      onClick={handleClick}
      variant={variant}
      color={color}
      size={size}
      sx={sx}
      width="30rem"
      formDefaultValue={{}}
    >
      <Typography paragraph>
        {t('This transaction has a negative balance owed to the merchant. A partial payout transaction will be generated to clear part of the negative balance you owe your client.')}
      </Typography>
      <Typography>{t('Please note that this action cannot be reversed, and the settlement will remain open unless you add the full amount')}</Typography>
      <InputsGroup>
        <CurrencyInput
          currency={record.currency}
          label={t('Add amount to pay')}
          source="partialPayoutAmount"
          placeholder={t('E.g. 3,000.00')}
          validate={[validatePartialPayoutAmount(record), ...validateRequired]}
        />
      </InputsGroup>
    </ModalButton>
  );
};

PartialPayoutButton.propTypes = {
  size: PropTypes.string,
  sx: PropTypes.shape(),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

PartialPayoutButton.defaultProps = {
  size: undefined,
  sx: undefined,
  variant: 'contained',
  disabled: false,
  color: undefined,
};

export default PartialPayoutButton;
