import React from 'react';

import Box from '@mui/material/Box';

import OverviewKpis from './OverviewKpis';
import PendingTasks from './PendingTasks';
import EntityExposureGroup from '../../entityv2/EntityExposureGroup';

const FactoringFinancerContentExposureKpis = () => (
  <Box display="flex" flexDirection="column" marginBottom="2rem">
    <PendingTasks />
    <EntityExposureGroup route="financerDashboard" />
    <OverviewKpis />
  </Box>
);

export default FactoringFinancerContentExposureKpis;
