import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import { useConstantContext } from '../../ConstantsContext';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import renderReference from '../../../utils/renderReference';

const usePaymentsReportFilters = () => {
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { entityRoles } = constants;

  return useState([
    <DateInput
      source="date"
      label={t('Snapshot date')}
    />,
    <ReferenceArrayInput
      source="filters.sellerIds"
      label={t('Merchant')}
      reference="entity-v2/reference"
      resource="/entity-v2/reference"
      filter={{ entityRoles: entityRoles.MERCHANT }}
    >
      <AutocompleteArrayInput
        label={t('Merchant')}
        optionText={renderReference}
        suggestionLimit={20}
      />
    </ReferenceArrayInput>,
    <DateInput
      source="filters.registeredFrom"
      label={t('Registered from')}
    />,
    <DateInput
      source="filters.registeredTo"
      label={t('Registered to')}
    />,
  ]);
};

export default usePaymentsReportFilters;
