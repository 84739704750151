import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  ArrayField,
  DateField,
  FunctionField,
  TextField,
  WrapperField,
  useRecordContext,
} from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Datagrid from '../designSystem/react-admin/Datagrid';

import MakeRefundButton from './MakeRefundButton';
import { useConstantContext } from '../ConstantsContext';

import CurrencyField from '../utils/CurrencyField';
import FieldsGroup from '../utils/FieldsGroup';
import MarkAsUnidentifiedButton from '../utils/MarkAsUnidentifiedButton';
import renderTransactionChip from '../utils/renderTransactionChip';

import currencyFormat from '../../utils/currencyFormat';
import { isStrictlyNegativeNumber } from '../../utils/validators';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

export const OverpaymentAction = () => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { balance, financialProduct, currency } = record;
  const { userActions, productFeatures } = constants;

  const isAssignable = productFeatures.assignable.includes(financialProduct);
  const canManagePaymentsPermission = useHasUserPermissions(
    constants,
    userActions.MANAGE_PAYMENTS,
  );

  if (!isStrictlyNegativeNumber(balance)
    || !isAssignable
    || !canManagePaymentsPermission
    || !isFinancer
  ) {
    return null;
  }

  return (
    <Box mt="0.15rem" display="flex" flexDirection="row" gap="1.5rem" alignItems="center">
      <Typography variant="caption">
        {t('Overpayment')}:&nbsp;<strong>{currencyFormat(-balance, currency)}</strong>
      </Typography>
      <MakeRefundButton />
    </Box>
  );
};

const TransactionsGroup = () => {
  const { t } = useTranslation();
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { decisionStatuses, accountTypes } = constants;
  const { partnerFinancing, accountType, partnerId } = record;
  const isPartnerFinanced = (accountType === accountTypes.ORDER
  && partnerFinancing
  && partnerFinancing.partnerDecision === decisionStatuses.PARTNER_GRANTED)
  || (accountType === accountTypes.ORDER && partnerId);

  return (
    <FieldsGroup title={t('Transactions')} actions={<OverpaymentAction />}>
      <ArrayField source="transactions" fullWidth>
        <Datagrid bulkActionButtons={false} rightAlignedColumns={['transactionDetails\\.deliveredQuantity', 'amount', 'actions']} cellMaxWidth="unset">
          <FunctionField
            label={t('Type')}
            render={renderTransactionChip}
          />
          <TextField source="description" />
          <DateField source="date" label={t('Date')} emptyText="-" />
          <TextField source="payinDetails.payerName" label={t('Payer name')} emptyText="-" />
          <TextField source="payinDetails.reference" label={t('Reference')} emptyText="-" />
          <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
          <WrapperField label={false} source="actions">
            <MarkAsUnidentifiedButton isPartnerFinanced={isPartnerFinanced} />
          </WrapperField>
        </Datagrid>
      </ArrayField>
    </FieldsGroup>
  );
};

export default TransactionsGroup;
