import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';
import { useConstantContext } from '../ConstantsContext';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import ModalButton from '../designSystem/ModalButton';

const MarkAsPaidButton = ({
  accountType,
  isCreditor,
  accountId,
  invoiceId,
}) => {
  const { isPaid, status } = useRecordContext();
  const { t } = useTranslation();
  const { isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { invoiceStatuses, accountTypes } = constants;

  const path = `account/${accountId}/mark-as-paid`;

  const {
    mutate: markAsPaid,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not mark account as paid'),
  });

  const handleClick = useCallback(() => {
    markAsPaid({
      invoiceId,
    });
    return true;
  }, [markAsPaid, invoiceId]);

  const showMarkAsPaidForBillingAccount = accountType === accountTypes.BILLING;

  if (isPaid
    || status === invoiceStatuses.PAID
    || (isMerchant && !isCreditor)) return null;

  return (
    <ModalButton
      modalTitle={t('Mark as paid')}
      openButtonLabel={t('Mark as paid')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Mark as paid')}
      variant="subAction"
      width="30rem"
    >
      {showMarkAsPaidForBillingAccount ? (
        <Typography paragraph>{t('The invoice for the billing will be marked as paid. This action will add a corresponding transaction and move this billing to \'Closed\'. Do you want to continue?')}</Typography>
      ) : (
        <>
          <Typography paragraph>{t('The invoice will be marked as paid and a corresponding payment will be added.')}</Typography>
          <Typography>{t('This action cannot be reverted.')}</Typography>
        </>
      )}
    </ModalButton>
  );
};

MarkAsPaidButton.propTypes = {
  accountType: PropTypes.string.isRequired,
  isCreditor: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
};

MarkAsPaidButton.defaultProps = {
  invoiceId: undefined,
};

export const MarkInvoiceAsPaid = ({
  accountType,
  isCreditor,
  invoiceId,
  accountId,
}) => <MarkAsPaidButton accountType={accountType} isCreditor={isCreditor} invoiceId={invoiceId} accountId={accountId} />;

MarkInvoiceAsPaid.propTypes = {
  accountType: PropTypes.string.isRequired,
  isCreditor: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default MarkAsPaidButton;
