import React from 'react';
import {
  ReferenceManyField,
  WrapperField,
  ShowButton,
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import CurrencyField from '../utils/CurrencyField';
import SafePagination from '../utils/SafePagination';
import FieldsGroup from '../utils/FieldsGroup';
import DropDownMenu from '../../utils/DropDownMenu';
import GroupedCreditStatusesChip from '../utils/GroupedCreditStatusChip';

import { useConstantContext } from '../ConstantsContext';

import Datagrid from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';

import GroupedCreditTransactions from '../groupedCredit/GroupedCreditTransactions';
import { FirstTransactionAmount } from '../groupedCredit/GroupedCreditShow';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const StyledShowButton = styled(ShowButton)(() => ({
  '& .MuiButton-startIcon': { display: 'none' },
}));

const GroupedCreditGroup = () => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewCustomer = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  );
  if (!canViewCustomer) return null;

  return (
    <FieldsGroup title={t('Grouped credit')}>
      <ReferenceManyField
        source="entityCoreId"
        reference="grouped-credit"
        target="debtorId"
        label=""
        sort={{ field: 'createdAt', order: 'DESC' }}
        pagination={<SafePagination />}
        perPage={10}
      >
        <Datagrid
          expand={(
            <GroupedCreditTransactions />
          )}
          bulkActionButtons={false}
          rightAlignedColumns={['subActions', 'balance', 'accountStatus']}
        >
          <TextField source="groupedCreditReference" label={t('Reference')} sortable={false} />
          {isFinancer && (
            <EntityReferenceField
              label={t('Merchant')}
              source="merchantId"
              reference="entity-v2"
              sortable={false}
            />
          )}
          <DateField
            source="createdAt"
            label={t('Creation date')}
          />
          <FirstTransactionAmount label={t('Initial amount')} />
          <CurrencyField source="balance" currencySource="currency" label={t('Balance')} />
          <WrapperField source="accountStatus">
            <GroupedCreditStatusesChip />
          </WrapperField>
          <WrapperField source="subActions" label={false}>
            <DropDownMenu>
              <StyledShowButton label={t('View grouped credit')} variant="subAction" />
            </DropDownMenu>
          </WrapperField>
        </Datagrid>
      </ReferenceManyField>
    </FieldsGroup>
  );
};

export default GroupedCreditGroup;
