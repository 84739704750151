import { useStore } from 'react-admin';

const featureFlagsInitialState = {
  enableLanguage: false,
  enableDeliveryConfirmation: false,
  enablePartnerFinancingInOtherCurrency: true,
};

const useFeatureFlags = () => useStore('fimento.featureFlags', featureFlagsInitialState);

export default useFeatureFlags;
