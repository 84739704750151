import React from 'react';
import { FormTab } from 'react-admin';
import { useTranslation } from 'react-i18next';

import FinancingInfoGroup from '../../FinancingInfoGroup';
import KycGroup from '../../KycGroup';

const FinancingKycTab = (props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormTab label={t('Financing & KYC')} {...props}>
      <FinancingInfoGroup />
      <KycGroup />
    </FormTab>
  );
};

export default FinancingKycTab;
