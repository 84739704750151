import { useAuthState } from 'react-admin';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import useCustomQuery from './hooks/useCustomQuery';
import { ChildrenProp } from '../utils/helpers/propTypes';
import { useConstantContext } from './ConstantsContext';

const ExchangeRateContext = React.createContext({});

export const ExchangeRateContextProvider = ({ children }) => {
  const { isLoading, authenticated } = useAuthState({});
  const { t } = useTranslation();
  const {
    data: exchangeRateData,
  } = useCustomQuery({
    key: 'exchangeRate',
    httpMethod: 'GET',
    path: 'exchangeRate',
    errorMessage: t('Error when fetching exchange rate'),
    refetchInterval: 60000,
    enabled: !isLoading && authenticated,
  });

  return (
    <ExchangeRateContext.Provider
      value={exchangeRateData?.data ?? []}
    >
      {children}
    </ExchangeRateContext.Provider>
  );
};

ExchangeRateContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

export const useExchangeRate = () => {
  const exchangeRate = useContext(ExchangeRateContext);
  const { constants } = useConstantContext();
  const { exchangeRateCoefficient } = constants;
  const exchangeRateMap = {};
  exchangeRate.forEach((rate) => {
    exchangeRateMap[rate.fromCurrency] = exchangeRateMap[rate.fromCurrency] || {};
    const existingRate = exchangeRateMap[rate.fromCurrency][rate.toCurrency];
    exchangeRateMap[rate.fromCurrency][rate.toCurrency] = existingRate
      || (rate.exchangeRate / exchangeRateCoefficient);
  });
  return exchangeRateMap;
};
