import React, { useCallback, useState } from 'react';
import {
  ListContext,
  required,
  useListContext,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useConstantContext } from '../ConstantsContext';

import buttonStyles from '../utils/ButtonStyles';

import ModalButton from '../designSystem/ModalButton';
import Button from '../designSystem/Button';
import TextInput from '../designSystem/react-admin/inputs/TextInput';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

import PartnerFinancingButton from './PartnerFinancingButton';
import PartnerFinancingButtonOld from './PartnerFinancingButtonOld';
import { PartnerFinancingContextProvider } from './PartnerFinancingContext';

const ApplicationListButtons = () => {
  const notify = useNotify();
  const { selectedIds, data: listRecords, filterValues } = useListContext();
  const { t } = useTranslation();
  const [isLoadingSendOrder, setIsLoadingSendOrder] = useState(false);
  const [featureFlag] = useFeatureFlags();

  const { constants } = useConstantContext();
  const resource = useResourceContext();
  const {
    decisionStatuses,
    resourceTypePaths,
    applicationResourceTypes,
  } = constants;

  const resourcePath = resourceTypePaths[applicationResourceTypes[listRecords[0].applicationType]];

  const showNotification = useCallback(({ data }) => {
    notify(
      `${data.message}${data.errors.length > 0 ? `\nErrors:\n${data.errors.join('\n')}` : ''}`,
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify]);
  const {
    mutate: updateDecision,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourcePath}/decision`,
    shouldRefresh: true,
    shouldUnselectAll: resource,
    errorMessage: t('Could not update the decision'),
    onSuccess: showNotification,
  });

  const handleReferClick = useCallback((e) => {
    e.preventDefault();
    updateDecision({
      decision: decisionStatuses.REFERRED,
      selectedIds,
    });
  }, [updateDecision, decisionStatuses.REFERRED, selectedIds]);

  const handleGrantClick = useCallback(() => {
    const data = {
      decision: decisionStatuses.GRANTED,
      selectedIds,
    };
    updateDecision(data);
    return true;
  }, [decisionStatuses.GRANTED, updateDecision, selectedIds]);

  const handleDenyStep = useCallback(({ reason }) => {
    const data = {
      decision: decisionStatuses.DENIED,
      decisionReason: reason,
      selectedIds,
    };
    updateDecision(data);
    return true;
  }, [updateDecision, decisionStatuses.DENIED, selectedIds]);

  const decisionFilter = filterValues.currentDecision;

  if (![
    decisionStatuses.DENIED,
    decisionStatuses.REFERRED,
  ].includes(decisionFilter)) return null;

  return (
    <Box display="flex" gap="0.5rem" flexDirection="right" margin="0.5rem 0.3rem">
      <ModalButton
        modalTitle={t('Grant')}
        actionLabel={t('Confirm')}
        openButtonLabel={t('Grant')}
        onClick={handleGrantClick}
        sx={buttonStyles.grantButton}
        disabled={isLoadingSendOrder || isLoading}
        width="30rem"
      >
        <Typography>{t('Are you sure you want to grant these applications?')}</Typography>
        <Typography>{t('The terms in the application will be applied as final decision terms.')}</Typography>
      </ModalButton>
      {decisionFilter !== decisionStatuses.REFERRED && (
        <Button
          onClick={handleReferClick}
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoadingSendOrder || isLoading}
        >
          {t('Refer')}
        </Button>
      )}
      {decisionFilter !== decisionStatuses.DENIED && (
        <ModalButton
          modalTitle={t('Deny')}
          actionLabel={t('Deny')}
          openButtonLabel={t('Deny')}
          onClick={handleDenyStep}
          sx={buttonStyles.denyButton}
          disabled={isLoadingSendOrder || isLoading}
          width="30rem"
          formDefaultValue={{}}
        >
          <TextInput
            source="reason"
            multiline
            label={t('Deny reason')}
            validate={[required()]}
          />
        </ModalButton>
      )}
      {decisionFilter !== decisionStatuses.GRANTED && (
        <PartnerFinancingContextProvider context={ListContext}>
          {featureFlag.enablePartnerFinancingInOtherCurrency ? (
            <PartnerFinancingButton
              setIsLoadingSendOrder={setIsLoadingSendOrder}
              isLoadingDecision={isLoading}
            />
          ) : (
            <PartnerFinancingButtonOld
              setIsLoadingSendOrder={setIsLoadingSendOrder}
              isLoadingDecision={isLoading}
            />
          )}
        </PartnerFinancingContextProvider>
      )}
    </Box>
  );
};

export default ApplicationListButtons;
