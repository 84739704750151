import React, { useCallback, useState } from 'react';
import {
  useRecordContext,
  required,
  useNotify,
  useRedirect,
  RecordContext,
} from 'react-admin';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import ModalButton from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import ButtonStyles from '../utils/ButtonStyles';
import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

import CustomerForm from './CustomerForm';
import FinancingApplicationTermsForm from './FinancingApplicationTermsFormNew';
import LoanApplicationTermsForm from './loanApplication/LoanApplicationTermsForm';
import { useConstantContext } from '../ConstantsContext';
import PartnerFinancingButton from './PartnerFinancingButton';
import PartnerFinancingButtonOld from './PartnerFinancingButtonOld';
import { PartnerFinancingContextProvider } from './PartnerFinancingContext';

const UpdateDecisionButton = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();
  const {
    id,
    currentDecision,
    applicationStep,
    applicationType,
    decisionReason,
    terms,
    orderAmount,
    orderCurrency,
    merchantId,
  } = record;
  const { t } = useTranslation();
  const [isLoadingSendOrder, setIsLoadingSendOrder] = useState(false);
  const [featureFlag] = useFeatureFlags();

  const { constants } = useConstantContext();

  const {
    applicationSteps,
    decisionStatuses,
    applicationTypes,
    resourceTypePaths,
    applicationResourceTypes,
  } = constants;

  const resourcePath = resourceTypePaths[applicationResourceTypes[applicationType]];
  const showNotification = useCallback(({ data }) => {
    if (data.needPeerReview) notify(data.message);
    if (applicationType === applicationTypes.LOAN && data.resourceId) {
      redirect(`/loan/${data.resourceId}`);
    }
  }, [notify, redirect, applicationType, applicationTypes]);
  const {
    mutate: updateDecision,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourcePath}/${id}/decision`,
    shouldRefresh: true,
    errorMessage: t('Could not update the decision'),
    onSuccess: showNotification,
  });

  const handleReferClick = useCallback((e) => {
    e.preventDefault();
    updateDecision({ decision: decisionStatuses.REFERRED });
  }, [updateDecision, decisionStatuses.REFERRED]);

  const handleGrantClick = useCallback((newTerms) => {
    const data = {
      decision: decisionStatuses.GRANTED,
      terms: newTerms,
    };
    updateDecision(data);
    return true;
  }, [decisionStatuses.GRANTED, updateDecision]);

  const handleDenyStep = useCallback(({ reason }) => {
    const data = {
      decision: decisionStatuses.DENIED,
      decisionReason: reason,
    };
    updateDecision(data);
    return true;
  }, [updateDecision, decisionStatuses.DENIED]);

  const isValidStatus = [
    decisionStatuses.GRANTED,
    decisionStatuses.REFERRED,
    decisionStatuses.DENIED,
  ].includes(currentDecision);

  if (applicationStep !== applicationSteps.CREATED || !isValidStatus) return null;

  return (
    <Box display="flex" gap="0.5rem" flexDirection="right" margin="0.5rem 0.3rem">
      {currentDecision !== decisionStatuses.GRANTED && (
        <ModalButton
          modalTitle={t('Grant')}
          actionLabel={t('Grant')}
          openButtonLabel={t('Grant')}
          onClick={handleGrantClick}
          sx={ButtonStyles.grantButton}
          disabled={isLoadingSendOrder || isLoading}
          formDefaultValue={terms}
          width="47.5rem"
        >
          {applicationType === applicationTypes.CUSTOMER && (
            <CustomerForm />
          )}
          {applicationType === applicationTypes.FINANCING && (
            <FinancingApplicationTermsForm
              orderAmount={orderAmount}
              orderCurrency={orderCurrency}
              merchantId={merchantId}
              termsFinancingCurrency={terms.financingCurrency}
              termsFeeSettings={terms.feeSettings}
            />
          )}
          {applicationType === applicationTypes.LOAN && (
            <LoanApplicationTermsForm />
          )}
        </ModalButton>
      )}
      {currentDecision !== decisionStatuses.REFERRED && (
        <Button
          onClick={handleReferClick}
          variant="contained"
          color="primary"
          size="small"
          disabled={isLoadingSendOrder || isLoading}
        >
          {t('Refer')}
        </Button>
      )}
      {currentDecision !== decisionStatuses.DENIED && (
        <ModalButton
          modalTitle={t('Deny')}
          actionLabel={t('Deny')}
          openButtonLabel={t('Deny')}
          onClick={handleDenyStep}
          sx={ButtonStyles.denyButton}
          disabled={isLoadingSendOrder || isLoading}
          width="30rem"
          formDefaultValue={{}}
        >
          <TextInput
            source="reason"
            multiline
            label={t('Deny reason')}
            defaultValue={decisionReason}
            validate={[required()]}
          />
        </ModalButton>
      )}
      {currentDecision !== decisionStatuses.GRANTED
      && applicationType === applicationTypes.FINANCING && (
        <PartnerFinancingContextProvider context={RecordContext}>
          {featureFlag.enablePartnerFinancingInOtherCurrency ? (
            <PartnerFinancingButton
              setIsLoadingSendOrder={setIsLoadingSendOrder}
              isLoadingDecision={isLoading}
            />
          ) : (
            <PartnerFinancingButtonOld
              setIsLoadingSendOrder={setIsLoadingSendOrder}
              isLoadingDecision={isLoading}
            />
          )}
        </PartnerFinancingContextProvider>
      )}
    </Box>
  );
};

export default UpdateDecisionButton;
