import { t } from 'i18next';

const validateFinancingCurrency = ({
  financingCurrency,
  orderCurrencies,
  fxRate,
}) => () => {
  let error;
  const financeSameCurrency = orderCurrencies
    .some((currency) => currency === financingCurrency);
  if (financeSameCurrency && fxRate && parseFloat(fxRate) !== 1) {
    error = t('Financing currency should be different from order currency when FX rate is not equal to 1');
  }
  return error;
};

export default validateFinancingCurrency;
