import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  useNotify,
  useResourceContext,
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { ExchangeRateContextProvider } from '../ExchangeRateContext';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';

import PartnerFinancingForm from './PartnerFinancingForm';
import { useConstantContext } from '../ConstantsContext';
import { usePartnerFinancing } from './PartnerFinancingContext';

const MemoizedFinancingForm = React.memo(() => (
  <ExchangeRateContextProvider>
    <PartnerFinancingForm />
  </ExchangeRateContextProvider>
));

const PartnerFinancingButton = ({
  isLoadingDecision,
  setIsLoadingSendOrder,
}) => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();
  const resource = useResourceContext();
  const {
    records,
    orderCurrencies,
  } = usePartnerFinancing();

  const haveSameOrderCurrency = orderCurrencies.length === 1 || new Set(orderCurrencies).size === 1;

  const showNotification = useCallback(({ data }) => {
    notify(
      `${data.message}${data.errors.length > 0 ? `\nErrors:\n${data.errors.join('\n')}` : ''}`,
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
    setIsLoadingSendOrder(false);
  }, [notify, setIsLoadingSendOrder]);

  const {
    mutate: sendOrder,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: 'order/partner-financing',
    shouldRefresh: true,
    shouldUnselectAll: resource,
    errorMessage: t('Could not send partner financing request'),
    onSuccess: showNotification,
    onError: () => setIsLoadingSendOrder(false),
  });

  const handleClick = useCallback((formData) => {
    const selectedOrderIds = records.map((record) => record.resourceId);

    if (haveSameOrderCurrency) {
      setIsLoadingSendOrder(true);
      sendOrder({
        selectedIds: selectedOrderIds,
        financingCurrency: formData.financingCurrency,
        fxRate: parseFloat(formData.fxRate) || 1,
      });
    }
    return true;
  }, [records, sendOrder, setIsLoadingSendOrder, haveSameOrderCurrency]);

  const shouldDisplayButton = clientInfo.defaultPartnerFinancer;

  if (!shouldDisplayButton) return null;

  return (
    <Box>
      <ModalButton
        modalTitle={t('Send to Moank')}
        actionLabel={t('Send')}
        openButtonLabel={t('Send to Moank')}
        onClick={handleClick}
        disabled={isLoading || isLoadingDecision}
        width="47.5rem"
        formDefaultValue={{}}
      >
        {haveSameOrderCurrency
          ? (
            <>
              <Typography paragraph>{t('This process will send the financing application to Moank. If the customer is not yet onboarded with Moank, their onboarding will be completed first. Once the customer is approved, we can proceed with managing the order application. Please note that the current FX rate is updated every 24 hours based on data from the Swedish central bank.')}</Typography>
              <MemoizedFinancingForm />
            </>
          )
          : <Typography paragraph>{t('The selected orders are in different currencies. All orders need to be in the same currency when using the bulk action to send to Moank for financing')}</Typography>}
      </ModalButton>
    </Box>
  );
};

PartnerFinancingButton.propTypes = {
  isLoadingDecision: PropTypes.bool.isRequired,
  setIsLoadingSendOrder: PropTypes.func.isRequired,
};

export default PartnerFinancingButton;
