import React from 'react';

import Box from '@mui/material/Box';

import MerchantCharts from './MerchantCharts';
import EntityExposureGroup from '../../entityv2/EntityExposureGroup';

const FactoringMerchantContentExposureKpis = () => (
  <Box display="flex" flexDirection="column" marginBottom="2rem">
    <EntityExposureGroup route="merchantDashboard" />
    <MerchantCharts />
  </Box>
);

export default FactoringMerchantContentExposureKpis;
