import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import ColumnsChart from '../../charts/ColumnsChart';
import ChartContainer from '../../charts/ChartContainer';

import roundNumber from '../../../utils/roundNumber';

import { useStatistics } from '../StatisticsContext';

const MerchantCharts = () => {
  const {
    statisticsData: {
      currency,
      kpis: {
        financedAmountPerMonth = [],
      },
    },
  } = useStatistics();
  const { t } = useTranslation();

  const financedLabels = financedAmountPerMonth.map((elem) => elem.month);
  const financedSerie = [{
    name: t('Financed'),
    data: financedAmountPerMonth.map((elem) => roundNumber(elem.amount)),
  }];

  return (
    <Grid
      container
      sx={{
        '@media': {
          maxWidth: 'calc(100% + 16px)',
        },
      }}
    >
      <Grid item lg={12}>
        <ChartContainer>
          <ColumnsChart
            title={t('Financed per month')}
            colours={['#1755F4']}
            labels={financedLabels}
            xTitle={new Date().getFullYear().toString()}
            serie={financedSerie}
            currency={currency}
          />
        </ChartContainer>
      </Grid>
    </Grid>
  );
};

export default MerchantCharts;
