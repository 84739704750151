import React from 'react';
import {
  FormTab,
  ReferenceField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../../utils/FieldsGroup';

import ApplicationDetailsGroup from '../../application/financingApplication/ApplicationDetailsGroup';
import FinancingApplicationRulesResultsGroup from '../../application/financingApplication/FinancingApplicationRulesResultsGroup';
import FinancingApplicationTermsGroup from '../../application/financingApplication/FinancingApplicationTermsGroup';

import DeliveryConfirmationGroup from '../DeliveryConfirmationGroup';
import EntityDetailsGroup from '../EntityDetailsGroup';

import EmptyData from '../../EmptyData';

const ApplicationTab = (props) => {
  const { t } = useTranslation();
  const { applicationId } = useRecordContext();

  return (
    !applicationId ? (
      <FormTab
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        label={t('Application')}
      >
        <FieldsGroup>
          <EmptyData isInvoiceServiceWithoutApplication />
        </FieldsGroup>
      </FormTab>
    ) : (
      <ReferenceField
        label=""
        source="applicationId"
        reference="financing-application"
        resource="/financing-application"
        link={false}
      >
        <FormTab
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          label={t('Application')}
        >
          <ApplicationDetailsGroup />
          <EntityDetailsGroup />
          <FinancingApplicationRulesResultsGroup />
          <DeliveryConfirmationGroup />
          <FinancingApplicationTermsGroup />
        </FormTab>
      </ReferenceField>
    )
  );
};

export default ApplicationTab;
