import React from 'react';

import SummaryTab from './tabs/SummaryTab';
import ApplicationTab from './tabs/ApplicationTab';
import FactoringTab from './tabs/FactoringTab';
import CommentsTab from './tabs/CommentsTab';
import HistoryTab from './tabs/HistoryTab';

import TabbedForm from '../designSystem/react-admin/TabbedForm';
import Edit from '../designSystem/react-admin/Edit';

import OrderTitle from './OrderTitle';
import useCanEdit from './useCanEdit';

import SaveOnlyToolbar from '../utils/SaveOnlyToolbar';

import { useConstantContext } from '../ConstantsContext';

const SaveToolbar = () => {
  const canEdit = useCanEdit();
  return canEdit ? <SaveOnlyToolbar /> : null;
};

const OrderEdit = () => {
  const { constants } = useConstantContext();
  const defaultFinancialProduct = constants.financialProducts.UNDISCLOSED_FACTORING_NON_RECOURSING;
  const defaultValues = () => ({ financialProduct: defaultFinancialProduct });

  /**
   * React admin is using the number of children to set the links between the tabs header
   * and content and does not allow us to override this behaviour.
   * Hence making conditionnal tab header impossible to code according to this React good practice https://www.notion.so/Conditionnal-rendering-in-React-Good-practices-9a4529b6360a4268aafa16cad8c2922e
   */
  const canEdit = useCanEdit();

  return (
    <Edit
      title={<OrderTitle />}
      mutationMode="optimistic"
      redirect={false}
      appLocation="home.order.edit"
    >
      <TabbedForm
        sanitizeEmptyValues
        toolbar={<SaveToolbar />}
        defaultValues={defaultValues}
      >
        <SummaryTab />
        <ApplicationTab />
        <FactoringTab />
        <CommentsTab />
        {canEdit && <HistoryTab />}
      </TabbedForm>
    </Edit>
  );
};

export default OrderEdit;
